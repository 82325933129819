<template>
  <div>
    <b-card title="SISTEMA DE CITAS MEDICAS EN LINEA">
<!--      <b-card-text>-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;</b-card-text>-->
      <b-card-text
        >
        Sistema de Citas Médicas, revise bien el horario asignado en su cita.
        <b-link
          href="www.google.com"
          target="_blank"
        >
        </b-link>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  }
}
</script>

<style></style>
